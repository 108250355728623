import {
  CAN_SITE_VISIT_REQUESTED_TAG,
  PROJECT_BOOKED_TAG,
  SITE_VISIT_REQUESTED_TAG,
} from 'Constant';
import moment from 'moment';
import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import { useReactToPrint } from 'react-to-print';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getEstimateInvoiceStatus } from 'Utils';
import { LAZYLAWN_APIS } from '../../../Adapters';
import { useMediaQuery } from '../../../hooks';
import { Alert, Button, ButtonLoader, Loader } from '../../Commons';
import { EstimateFeedBackSection, FooterSection } from '../../Sections';
import ButtonMailto from './MailButton';
import './styles.css';

export const EstimatePage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCalendly, setLoadingCalendly] = useState(false);
  const [fetchingKeapContact, setFetchingKeapContact] = useState(false);
  const [estimate, setEstimate] = useState({});
  const [estimateData, setEstimateData] = useState({});
  const [tags, setTags] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { estimateIdEncrypted } = useParams();

  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const gaEventTracker = useAnalyticsEventTracker('Estimate Quotes Views');
  const { isMobile } = useMediaQuery();

  const isManualEstimate = location.search.includes('manual=true');

  useEffect(() => {
    const getEstimateDetail = async () => {
      if (!navigator.onLine) return;
      setIsLoading(true);
      try {
        const res = await LAZYLAWN_APIS.get.getEstimate(estimateIdEncrypted);
        if (!res?.data?.estimate) {
          setIsLoading(false);
          Alert({
            variant: 'error',
            message: 'Failed to retrieve estimate details. Please try again later.',
          });
          navigate('/estimate-not-found');
          return;
        }
        gaEventTracker(res?.data?.estimate?.estimate_number);
        setEstimate(res?.data?.estimate);
        setIsLoading(false);
        const estimateDetails = res?.data?.estimate;
        if (window && document && estimateDetails) {
          const contactDetails = estimateDetails?.contacts?.[0];
          const scriptId = 'google-analytics-script';
          let script = document.getElementById(scriptId);

          // If the script doesn't exist, create a new one
          if (!script) {
            script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = scriptId;
            document.body.appendChild(script);
          }
          window.dataLayer = window.dataLayer || [];
          const scriptContent = `
               dataLayer.push({
                        event: "generate_lead",
                        ecommerce: null,
                        firstname: "${contactDetails?.fname}",
                        lastname: "${contactDetails?.lname}",
                        phone: "",
                        email: "${contactDetails?.email}",
                        zip: "${estimateDetails?.code}",
                        city: "${estimateDetails?.city}",
                        state: "${estimateDetails?.province}",
                        country: "${estimateDetails?.country}"
                    });
                `;
          script.textContent = scriptContent;
          // Append the script to the body
          document.body.appendChild(script);
        }
        if (estimateDetails?.contacts[0]?.email) {
          setLoadingCalendly(true);
          const estimateDataRes = await LAZYLAWN_APIS.get.getEstimateData(
            estimateIdEncrypted,
            estimateDetails?.customerid,
            estimateDetails?.contacts[0]?.email,
          );
          setLoadingCalendly(false);
          setEstimateData(estimateDataRes?.data?.data);
          setTags(estimateDataRes?.data?.data?.contact?.tags);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error('Failed to retrieve estimate details. Please try again later.');
        navigate('/estimate-not-found');
      }
    };
    getEstimateDetail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estimateIdEncrypted]);

  useEffect(() => {
    const getKeapClient = async () => {
      if (!estimateData?.contact?.id) return;
      try {
        setFetchingKeapContact(true);
        const res = await LAZYLAWN_APIS.get.getContactTags(estimateData?.contact?.email);
        setTags(res.data.tags);
        setFetchingKeapContact(false);
      } catch (error) {
        setFetchingKeapContact(false);
      }
    };

    getKeapClient();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const clientName = `${estimate?.fname} ${estimate?.lname}`;
  const clientStreet = `${estimate?.street}`;
  const clientCity = `${estimate?.city}`;
  const clientProvince = `${estimate?.province} ${estimate?.code} ${estimate?.country}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const bitlyLink =
    estimateData?.contact?.freshbookUrl ?? `https://my.lazylawn.ca/${estimateIdEncrypted}`;

  const isFinalEstimate = getEstimateInvoiceStatus(estimate);

  const bookSiteVisitHandler = async () => {
    if (!navigator.onLine) {
      Alert({ variant: 'error', message: 'Please check your internet connection and try again.' });
      return;
    }
    if (isFinalEstimate && !estimateData?.hasInvoices) {
      setLoadingCalendly(true);
      await LAZYLAWN_APIS.createClientInvoice(estimateIdEncrypted);
      // Add 144(project booked) tag to keap contact
      await LAZYLAWN_APIS.applyTagsToContact(`${PROJECT_BOOKED_TAG}`, estimateData?.contact?.id);
      setLoadingCalendly(false);
      return;
    }

    if (estimateData?.isExternal) {
      try {
        setLoadingCalendly(true);
        setShow(true);
        await LAZYLAWN_APIS.applyTagsToContact(
          `${CAN_SITE_VISIT_REQUESTED_TAG}`,
          estimateData?.contact?.id,
        );
        setLoadingCalendly(false);
        LAZYLAWN_APIS.updateKeapCustomFields(estimateData?.contact?.id, [
          {
            id: 3, // Lead Status => LeadStatus
            content: 'CAN - Site Visit Requested',
          },
        ]);
      } catch (error) {
        Alert({ variant: 'error', message: 'Something has gone wrong, Please try again later.' });
        setLoadingCalendly(false);
      }
      return;
    }

    if (!estimateData?.calendlyLink && !estimateData?.isExternal) {
      try {
        setLoadingCalendly(true);
        setShow(true);
        await LAZYLAWN_APIS.applyTagsToContact(
          `${SITE_VISIT_REQUESTED_TAG}`,
          estimateData?.contact?.id,
        );
        setLoadingCalendly(false);
        LAZYLAWN_APIS.updateKeapCustomFields(estimateData?.contact?.id, [
          {
            id: 3, // Lead Status => LeadStatus
            content: 'Site Visit Requested',
          },
        ]);
      } catch (error) {
        Alert({ variant: 'error', message: 'Something has gone wrong, Please try again later.' });
        setLoadingCalendly(false);
      }
      return;
    }

    // Add 848(site visit requested) tag to keap contact
    LAZYLAWN_APIS.applyTagsToContact(`${SITE_VISIT_REQUESTED_TAG}`, estimateData?.contact?.id);
    if (estimate?.status < 4) {
      await LAZYLAWN_APIS.acceptFreshbookEstimate(estimateIdEncrypted);
    }

    // TODO: Update keap contact LeadStatus to Site Visit Requested
    LAZYLAWN_APIS.updateKeapCustomFields(estimateData?.contact?.id, [
      {
        id: 3, // Lead Status => LeadStatus
        content: 'Site Visit Requested',
      },
    ]);
    localStorage.setItem('keapContact', JSON.stringify(estimateData?.contact));

    /* eslint-disable prefer-template */
    const newWindow = window.open(
      `/book-site-visit?q=${estimateData?.calendlyLink?.split('.com/')[1]}`,
      '_blank',
      'toolbar=1, scrollbars=1, resizable=1, width=' + 1015 + ', height=' + 800,
    );

    newWindow.localStorage.setItem('keapContact', JSON.stringify(estimateData?.contact));
  };

  const emailSubject = `Lawn Estimate from Lazy Lawn® #${estimate?.estimate_number}`;
  const emailBody = `Hello, I just wanted to share this guesstimate(${bitlyLink}) we got from Lazy Lawn® for our artificial grass lawn.\nShould we book a site visit and get their awesome samples?\nLet me know anytime.`;

  const componentRef = React.useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Lawn Estimate #${estimate?.estimate_number}`,
    removeAfterPrint: true,
  });

  const bookProjectLabel =
    tags?.includes(`${PROJECT_BOOKED_TAG}`) && estimateData?.hasInvoices
      ? 'Project Booked'
      : 'Book Project';

  const getDisabledStatus = () => {
    if (loadingCalendly) return true;
    if (tags?.includes(`${PROJECT_BOOKED_TAG}`) && estimateData?.hasInvoices) return true;

    return false;
  };

  const buttonLabel = isFinalEstimate ? bookProjectLabel : 'Book Site Visit';

  return (
    <div className='font-Monteserrat bg-white'>
      {isLoading || !estimate?.id ? (
        <Loader message='Generating Estimate...' customClass='min-h-screen' />
      ) : (
        <>
          <div
            className='w-full flex justify-around items-center sticky top-0 bg-white z-10'
            style={{
              padding: isMobile ? '16px 20px' : '16px 150px',
              borderBottom: '1px solid #eee',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div
              className={`flex justify-end font-bold text-secondary font-lawn ${
                isMobile ? 'text-lg' : 'text-3xl'
              }`}
            >
              <h1>
                Estimate<span className='text-[#001b40] ml-3'>#{estimate?.estimate_number}</span>
              </h1>
            </div>
            <div
              className='flex items-center justify-center gap-4 font-Monteserrat'
              style={{
                marginTop: isMobile ? '16px' : '0px',
              }}
            >
              <button
                type='button'
                onClick={handlePrint}
                className='btn btn-primary hover:text-secondary'
              >
                Print
              </button>
              <ButtonMailto
                label='Share'
                mailto={`mailto:?body=${encodeURIComponent(emailBody)}&subject=${emailSubject}`}
              />
              {!show && !isManualEstimate && (
                <Button
                  onClick={bookSiteVisitHandler}
                  disabled={getDisabledStatus()}
                  variant='secondary'
                  customClass='flex items-center'
                >
                  {loadingCalendly ? <ButtonLoader /> : buttonLabel}
                </Button>
              )}
            </div>
          </div>

          <div
            className='w-full bg-[#c3e6b3] text-[#001b40] flex justify-center items-center'
            style={{
              padding: isMobile ? '16px 20px' : '16px 150px',
              flexDirection: isMobile ? 'column' : 'row',
            }}
          >
            <div className='text-darkGreen'>
              Estimate created on {moment(estimate?.created_at).format('MMM DD, YYYY')}.
            </div>
          </div>
          {show && (
            <div className='lazy-estimate-inner-container mx-auto mt-4'>
              <div className='flex justify-center py-[15px] px-[20px] font-poppins bg-[hsla(113,100%,33%,15%)] text-[#3c8224] rounded-xl mx-2'>
                <div className='text-justify'>
                  <p className='mb-2 leading-5'>
                    Thanks for your interest in Lazy Lawn, We&apos;ll be emailing and texting you
                    the information of our local authorized installer. Please feel free to contact
                    them to book your site visit thanks.
                  </p>
                  <p className='mb-2 leading-6'>
                    Should you have any questions, Please contact{' '}
                    <a href='tel:1-888-622-5296' className='font-semibold hover:text-secondary'>
                      1-888-622-5296
                    </a>
                  </p>
                </div>
                <div className='font-bold pl-4 text-[#3c8224]'>
                  <MdClose
                    className='text-2xl cursor-pointer hover:text-secondary'
                    onClick={() => setShow(false)}
                  />
                </div>
              </div>
            </div>
          )}

          <section className='estimate-container flex justify-center items-center pt-5'>
            <div className='lazy-estimate-inner-container mx-auto' ref={componentRef}>
              <div className='invoice lazy-estimate-template font-poppins'>
                <div className='table w-full'>
                  <div className='flex justify-between items-start pb-4 '>
                    <div className='table w-full align-top'>
                      <div className='text-left'>
                        <div>Lazy Lawn®</div>
                        <div className='lazy-address'>
                          <span className='lazy-address-street'>18B-3200 Dufferin St. #113</span>
                          <br />
                          <span className='lazy-address-city'>Toronto</span>
                          <span>, </span>
                          <span className='lazy-address-province'>Ontario</span>{' '}
                          <span className='lazy-address-code'> M6A 0A1</span>
                          <br />
                          <span className='lazy-bus-phone'>1.888.622.LAWN</span>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center '>
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/logos/lazylawn-logo.png`}
                        alt='Lazy Lawn®'
                        className='w-56'
                      />
                    </div>
                  </div>
                  <div className='table-row'>
                    <div className='table-cell h-[1px] pb-5'>
                      <div className='table w-full'>
                        <div className='estimate-header'>
                          <div className='estimate-header-address'>
                            <div className='lazy-client-address'>
                              <div className='lazy-estimate-client-organization'>{clientName}</div>
                              <div className='lazy-estimate-client-street'>{clientStreet}</div>
                              <span className='lazy-estimate-client-city'>{clientCity}</span>,
                              <span className='lazy-estimate-client-province'>
                                {clientProvince}
                              </span>
                            </div>
                          </div>
                          <div className='estimate-header-estimate-metadata font-Monteserrat font-semibold'>
                            <div className='estimate-header-estimate-metadata-estimate-number'>
                              <div className='text-secondary'>Estimate Number:</div>
                              <div className='estimate-number'>{estimate?.estimate_number}</div>
                            </div>
                            <div className='estimate-header-estimate-metadata-estimate-date'>
                              <div className='estimate-date text-secondary'>Estimate Date:</div>
                              <div className=''>
                                {moment(estimate?.created_at).format('MMM DD, YYYY')}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='table-row'>
                    <div className='table-cell h-[1px] pb-5'>
                      <div id='ember28'>
                        <div id='ember29'>
                          <table className='table w-full'>
                            <tr className='invoice-lineItem invoice-lineItem--header border-secondary font-Monteserrat font-bold'>
                              <th className='invoice-lineItemCell invoice-header-title line-items-desc'>
                                Description
                              </th>
                              <th className='invoice-lineItemCell invoice-header-title'>Rate</th>
                              <th className='invoice-lineItemCell invoice-header-title'>Qty</th>
                              <th className='invoice-lineItemCell invoice-header-title'>
                                Line Total
                              </th>
                            </tr>

                            {estimate?.lines?.map((line) => {
                              const {
                                name,
                                description,
                                qty,
                                unit_cost,
                                taxName1,
                                taxName2,
                                taxAmount1,
                                taxAmount2,
                                amount,
                              } = line;
                              const totalLineAmount = Number(amount?.amount).toLocaleString(
                                'en-US',
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                },
                              );

                              const unitCost = Number(unit_cost?.amount).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              });

                              const hasTax = (taxName1 && taxAmount1) || (taxName2 && taxAmount2);

                              return (
                                <tr className='invoice-lineItem js-invoice-line-item' key={name}>
                                  <td className='invoice-lineItemCell invoice-lineItemCell--nonNumeric line-items-desc'>
                                    <div className='js-invoice-line-item-name font-Monteserrat font-[500]'>
                                      {name}
                                    </div>
                                    <div className='invoice-lineItemSecondaryData whitespace-pre-line'>
                                      {description}
                                    </div>
                                  </td>
                                  <td className='invoice-lineItemCell js-invoice-line-item-rate'>
                                    ${unitCost}
                                    {hasTax && (
                                      <div className='invoice-lineItemSecondaryData'>
                                        {`+${taxName1}${taxName2 ? `, ${taxName2}` : ''}`}
                                      </div>
                                    )}
                                  </td>
                                  <td className='invoice-lineItemCell js-invoice-line-item-quantity'>
                                    {qty}
                                  </td>
                                  <td className='invoice-lineItemCell js-invoice-line-item-total'>
                                    ${totalLineAmount}
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>

                        <div id='ember30' className='table w-full invoice-lineItems--summary'>
                          <div id='ember31'>
                            <div
                              id='ember-basic-dropdown-content-ember33'
                              className='ember-basic-dropdown-content-placeholder'
                              style={{ display: 'none' }}
                            />
                          </div>
                          <div id='ember34'>
                            <div
                              id='ember-basic-dropdown-content-ember36'
                              className='ember-basic-dropdown-content-placeholder'
                              style={{ display: 'none' }}
                            />
                          </div>
                          <div id='ember37'>
                            <div
                              id='ember-basic-dropdown-content-ember39'
                              className='ember-basic-dropdown-content-placeholder'
                              style={{ display: 'none' }}
                            />
                          </div>
                          <div className='invoice-lineItem'>
                            <div className='invoice-lineItemCell'>
                              <div className='u-paddingBottom--quarter'>
                                <div>Subtotal</div>
                              </div>

                              {estimate?.taxes.length > 0 && (
                                <div className='invoice-taxLine'>
                                  {estimate?.taxes.map((tax) => (
                                    <div
                                      className='js-invoice-tax-summary-label-0'
                                      key={tax.tax_name}
                                    >
                                      {tax.tax_name} ({tax.tax_percentage}%)
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>

                            <div className='invoice-lineItemCell'>
                              <div className='u-paddingBottom--quarter'>
                                <div className='js-invoice-subtotal'>{estimate?.sub_total}</div>
                              </div>
                              {estimate?.taxes.length > 0 && (
                                <div className='invoice-taxLine'>
                                  {estimate?.taxes.map((tax) => (
                                    <div
                                      className='js-invoice-tax-summary-amount-0'
                                      key={tax.tax_name}
                                    >
                                      +{tax.tax_total}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>

                          <div className='invoice-lineItem invoice-lineItem--amountDue font-Monteserrat text-md'>
                            <div className='invoice-lineItemCell font-bold '>
                              <span className='text-secondary'>
                                Estimate Total ({estimate?.amount?.code})
                              </span>
                            </div>
                            <div className='invoice-lineItemCell js-estimate-total font-semibold'>
                              $
                              {Number(estimate?.amount?.amount).toLocaleString('en-US', {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(estimate?.notes || estimate?.terms) && (
                    <div className='table-row w-full'>
                      {estimate?.notes && (
                        <div className='notes-container w-full'>
                          <div className='invoice-label text-secondary font-Monteserrat font-bold mb-1'>
                            Notes
                          </div>
                          <div className='invoice-notes'>
                            <pre className='whitespace-pre-line'>{estimate?.notes}</pre>
                          </div>
                        </div>
                      )}
                      {estimate?.terms && (
                        <div className='terms-container w-full'>
                          <div className='invoice-label text-secondary font-Monteserrat font-bold mb-1'>
                            Terms
                          </div>
                          <div className='invoice-notes'>
                            <pre className='whitespace-pre-line'>{estimate?.terms}</pre>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          {isManualEstimate && (
            <div className='text-center mb-3'>
              New Manual Estimate?{' '}
              <Link to='/manual-estimate' className='text-secondary underline'>
                Click here
              </Link>
            </div>
          )}
          {!show && !isManualEstimate && (
            <div className='estimate-actions md:mb-4'>
              <div
                className='calendly-btn'
                style={{
                  margin: isMobile ? '2rem 0 1rem' : '0',
                }}
              >
                <Button
                  onClick={bookSiteVisitHandler}
                  disabled={getDisabledStatus()}
                  variant='secondary'
                  customClass='py-2 px-6 text-lg'
                  id='totalWrapper'
                >
                  {loadingCalendly ? <ButtonLoader /> : buttonLabel}
                </Button>
              </div>
            </div>
          )}
          {!isManualEstimate && (
            <EstimateFeedBackSection
              contactId={estimateData?.contact?.id}
              setRefresh={setRefresh}
              refresh={refresh}
              tags={tags}
              fetchingKeapContact={fetchingKeapContact}
            />
          )}
          <FooterSection />
        </>
      )}
    </div>
  );
};
