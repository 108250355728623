/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-this-in-sfc */
import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { ButtonLoader } from 'Components/Commons';
import { contactValidationSchema } from 'Components/Commons/Schema';
import { INSTALLATION_OPTIONS } from 'Constant';
import { useNavigate } from 'react-router-dom';
import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import { LAZYLAWN_APIS } from '../../../Adapters';
import ContactForm from './ContactForm';
import GrassTypes from './GrassTypes';
import MapArea from './MapArea';
import Options from './Options';
import PlaygroundOptions from './PlaygroundOptions';

const initialValues = {
  grass: '',
  installationOption: '',
  estimateArea: '',
  playgroundType: null,
  fname: '',
  lname: '',
  email: '',
  phone: '',
  mapSnapshot: '',
  address: {
    postal_code: '',
    country: '',
    route: '',
    street_number: '',
    locality: '',
    administrative_area_level_1: '',
  },
  source: 'my',
};

let marker = [];
let polygon_area;
let marker_no = 0;
let marker_count = 0;
let map;

const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const DYE = () => {
  const [loading, setLoading] = useState(false);
  const [showTitle, setShowTitle] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedEstimateArea, setSelectedEstimateArea] = useState('');

  const gaEventTracker = useAnalyticsEventTracker('New Estimates Quotes');
  const searchInputRef = useRef(null);

  const [address, setAddress] = useState({
    postal_code: '',
    country: '',
    route: '',
    street_number: '',
    locality: '',
    administrative_area_level_1: '',
  });
  const navigate = useNavigate();

  function displaySearchResults(mp, autoComplete, markers) {
    setShowTitle(false);
    setShowButtons(true);
    const place = autoComplete?.getPlace();
    if (!place) {
      return;
    }
    const newObj = {};
    place?.address_components.forEach((item) => {
      const key = item.types[0];
      const value = item.long_name;
      newObj[key] = value;
    });
    setAddress(newObj);
    gaEventTracker(place.name);

    // Clear out the old markers.
    markers.forEach((m) => {
      m.setMap(null);
    });

    // For each place, get the icon, name and location.
    const bounds = new window.google.maps.LatLngBounds();

    if (!place.geometry) {
      // console.log('Returned places contains no geometry');
      return;
    }
    const icon = {
      url: place.icon,
      size: new window.google.maps.Size(71, 71),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(17, 34),
      scaledSize: new window.google.maps.Size(25, 25),
    };

    // Create a marker for each places.
    markers.push(
      new window.google.maps.Marker({
        map: mp,
        icon,
        title: place.name,
        position: place.geometry.location,
      }),
    );

    if (place.geometry.viewport) {
      // Only geocodes have viewport.
      bounds.union(place.geometry.viewport);
    } else {
      bounds.extend(place.geometry.location);
    }

    map.setZoom(17);
    map.fitBounds(bounds);
  }

  const draw_polygon = () => {
    if (marker_count >= 2) {
      if (polygon_area) {
        polygon_area.setMap(null);
      }
      const polygon = [];
      for (let i = 0; i < marker_count; i++) {
        polygon[i] = new window.google.maps.LatLng(
          marker[i].position.lat(),
          marker[i].position.lng(),
        );
      }

      polygon_area = new window.google.maps.Polygon({
        paths: polygon,
        strokeColor: '#00B300',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#00B300',
        fillOpacity: 0.3,
      });

      polygon_area.setMap(map);
      const total_area = window.google.maps.geometry.spherical.computeArea(polygon_area.getPath());
      const totalArea = (total_area * 10.764).toFixed(2);
      setSelectedEstimateArea(totalArea);
    }
  };

  function drag_marker(e) {
    const latlng = e.latLng;
    const lat = latlng.lat();
    const lng = latlng.lng();
    const new_latlng = new window.google.maps.LatLng(lat, lng);
    marker[this.marker_id].setPosition(new_latlng);
    draw_polygon();
  }

  const clickMapHandler = (e) => {
    const latlng = e.latLng;
    marker[marker_no] = new window.google.maps.Marker({
      position: latlng,
      map,
      marker_id: marker_no,
      title: `marker_${marker_no}${labels[marker_no % labels.length]}`,
      label: labels[marker_no % labels.length],
      draggable: true,
    });
    window.google.maps.event.addListener(marker[marker_no], 'drag', drag_marker);
    marker_no++;
    marker_count++;
    draw_polygon();
  };

  const getSnapshotOfElement = (element, posX, posY, convertedSnapshotCallback) => {
    html2canvas(document.querySelector(element), {
      useCORS: true,
      allowTaint: false,
    }).then((canvas) => {
      const context = canvas.getContext('2d');
      const imageData1 = context.getImageData(posX, posY, canvas.width, canvas.height).data;
      const outputCanvas = document.createElement('canvas');
      const outputContext = outputCanvas.getContext('2d');
      outputCanvas.width = canvas.width;
      outputCanvas.height = canvas.height;

      const outputIData = outputContext.createImageData(canvas.width, canvas.height);
      outputIData.data.set(imageData1);
      outputContext.putImageData(outputIData, 0, 0);
      convertedSnapshotCallback(outputCanvas.toDataURL());
    });
  };

  const initiateMapSnapshot = (setFieldValue) => {
    getSnapshotOfElement('#map', 0, 0, (base64MapData) => {
      setFieldValue('mapSnapshot', base64MapData);
    });
  };

  useEffect(() => {
    function initMap() {
      const start_latitude = 44.012893;
      const start_longitude = -79.441833;

      const latlng = new window.google.maps.LatLng(start_latitude, start_longitude);
      const opts = {
        tilt: 0,
        zoom: 17,
        center: latlng,
        mapTypeId: window.google.maps.MapTypeId.HYBRID,
        disableDefaultUI: true,
        zoomControl: true,
        scaleControl: true,
        rotateControl: true,
        fullscreenControl: true,
        gestureHandling: 'cooperative',
      };

      map = new window.google.maps.Map(document.getElementById('map'), opts);
      window.google.maps.event.addListener(map, 'click', clickMapHandler);

      const input = document.getElementById('pac-input');
      const options = {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'geometry', 'icon', 'name', 'formatted_address'],
        strictBounds: false,
        // types: ['geocode'],
      };
      const autocomplete = new window.google.maps.places.Autocomplete(input, options);

      let markers = [];
      map.addListener('bounds_changed', () => {
        autocomplete.setBounds(map.getBounds());
      });

      autocomplete.addListener('place_changed', () => {
        for (let i = 0; i < marker_count; i++) {
          marker[i].setMap(null);
        }
        if (polygon_area) {
          polygon_area.setMap(null);
        }
        marker_count = 0;
        marker = [];
        marker_no = 0;
        setSelectedEstimateArea('');

        const place = autocomplete.getPlace();

        if (!place.geometry || !place.geometry.location) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17); // Why 17? Because it looks good.
        }

        const newObj = {};
        place?.address_components.forEach((item) => {
          const key = item.types[0];
          const value = item.long_name;
          newObj[key] = value;
        });
        setAddress(newObj);

        // Clear out the old markers.
        markers.forEach((m) => {
          m.setMap(null);
        });
        markers = [];

        // For each place, get the icon, name and location.
        const bounds = new window.google.maps.LatLngBounds();

        if (!place.geometry) {
          // console.log('Returned place contains no geometry');
          return;
        }
        const icon = {
          url: place.icon,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new window.google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          }),
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        map.fitBounds(bounds);
        const searchBtn = document.getElementById('get-start');

        searchBtn.onclick = () => {
          searchBtn.style.display = 'none';
          document.getElementById('maparea').style.display = 'block';
          displaySearchResults(map, autocomplete, markers);
        };
      });
    }

    let ignore = false;

    if (!ignore) initMap();
    return () => {
      ignore = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearSelectedArea = (setFieldValue) => {
    for (let i = 0; i < marker_count; i++) {
      marker[i].setMap(null);
    }
    if (polygon_area) {
      polygon_area.setMap(null);
    }
    marker_count = 0;
    marker = [];
    marker_no = 0;
    setSelectedEstimateArea('');
    setFieldValue('estimateArea', '');
    setFieldValue('mapSnapshot', '');
  };

  const handleNextStep = (setFieldValue) => {
    if (step === 1) {
      if (
        address.country &&
        address.administrative_area_level_1 &&
        address.locality &&
        selectedEstimateArea
      ) {
        initiateMapSnapshot(setFieldValue);
        setStep(step + 1);
        if (setFieldValue) setFieldValue('address', address);
      } else {
        toast.error('Click on at least 3 places on the map to plot your square footage');
      }
    }
  };

  /**
    @name getCurrentComponent
    @description This function is used to get the current component based on the step
    @param {number} stp - step number
    @param {object} values - formik values
    @param {function} setFieldValue - formik setFieldValue
    @returns {component} - returns the current component
   */
  const getCurrentComponent = (stp, values, setFieldValue) => {
    switch (stp) {
      case 1:
        return (
          <MapArea
            step={step}
            selectedEstimateArea={selectedEstimateArea}
            setFieldValue={setFieldValue}
            ref={searchInputRef}
          />
        );
      case 2:
        return (
          <Options step={step} setStep={setStep} values={values} setFieldValue={setFieldValue} />
        );
      case 3:
        return (
          <PlaygroundOptions
            step={step}
            setStep={setStep}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case 4:
        return (
          <GrassTypes step={step} setStep={setStep} values={values} setFieldValue={setFieldValue} />
        );
      case 5:
        return <ContactForm step={step} values={values} loading={loading} />;
      default:
        return (
          <MapArea
            step={step}
            selectedEstimateArea={selectedEstimateArea}
            ref={searchInputRef}
            setFieldValue={setFieldValue}
          />
        );
    }
  };

  const getButtonLabel = () => {
    if (loading) return <ButtonLoader />;
    if (step === 5) return 'Submit';
    return 'Next';
  };

  const getDisabled = () => {
    if (loading) return true;
    if (step === 1) {
      if (
        address.country &&
        address.administrative_area_level_1 &&
        address.locality &&
        selectedEstimateArea
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (step === 1) {
      searchInputRef.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  /**
   * A function that handles estimate form submission
   * @name handleEstimateFormSubmission
   * @description handles form submission
   * @param {object} values - form values
   */
  const handleEstimateFormSubmission = async (values) => {
    if (!navigator.onLine) {
      toast.error('You are offline. Please connect to the internet to generate estimate.');
      return;
    }

    //  ADDED ON BRAN's REQUEST FOR CONVERSION TRACKING
    if (window) {
      // @ts-ignore
      window.uetq = [];
      // @ts-ignore
      window.uetq.push('set', {
        pid: {
          em: values.email,
          ph: values.phone,
        },
      });
    }
    try {
      setLoading(true);
      const res = await LAZYLAWN_APIS.generateEstimate(values);
      if (res.data?.estimateUrl) {
        navigate(`/estimate/${res.data.estimateUrl}`);
      } else {
        toast.error('Something went wrong, Please try again');
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      toast.error('Something went wrong, Please try again later');
      setLoading(false);
    }
  };

  return (
    <div className='md:w-3/4  estimate-section  flex justify-center flex-col max-w-screen-md'>
      <div className='flex justify-center p-3 mb-3'>
        <img
          src={`${process.env.PUBLIC_URL}/assets/logos/lazylawn-logo.png`}
          alt='Lazy Lawn®'
          className='w-80'
        />
      </div>

      {showTitle && (
        <div className='py-1 text-center pb-6'>
          <h2 className='title text-center font-semibold font-lawn pb-2 text-lazyGreen'>
            DIY Quotes
          </h2>
          <p className='font-Monteserrat text-center text-primary'>
            <span className='font-bold'>AI Generated Estimates.</span> In less than 45 seconds.
          </p>
        </div>
      )}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={contactValidationSchema}
        className='needs-validation'
        onSubmit={async (values, { resetForm }) => {
          await handleEstimateFormSubmission(values);
          resetForm();
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            {getCurrentComponent(step, values, setFieldValue)}
            {showButtons && (
              <div className='flex justify-center gap-2 my-3 py-4'>
                {step > 2 && (
                  <button
                    disabled={loading}
                    type='button'
                    onClick={() => {
                      if (
                        values.installationOption === INSTALLATION_OPTIONS.PUTTING_GREEN &&
                        step === 5
                      ) {
                        setStep(2);
                      } else if (
                        values.installationOption !== INSTALLATION_OPTIONS.PLAYGROUND &&
                        step === 4
                      ) {
                        setStep(2);
                      } else {
                        setStep(step - 1);
                      }
                    }}
                    className='bg-white text-secondary border-secondary hover:bg-secondary hover:text-white hover:border-secondary disabled:bg-tertiary disabled:border-tertiary font-bold text-xl w-[10rem] cursor-pointer uppercase py-2 px-5 rounded-full mt-2 font-Monteserrat border-2  disabled:cursor-not-allowed'
                  >
                    Previous
                  </button>
                )}
                {step === 1 && (
                  <button
                    disabled={loading}
                    type='button'
                    onClick={() => clearSelectedArea(setFieldValue)}
                    className='bg-[#333] text-white font-bold text-lg uppercase py-2 px-5 rounded-full  mt-2 font-Monteserrat border-2 border-[#333] hover:bg-[#000] hover:text-secondary hover:border-[#000]'
                  >
                    Clear Map
                  </button>
                )}

                {(step === 1 || step === 5) && (
                  <button
                    onClick={step === 5 ? null : () => handleNextStep(setFieldValue)}
                    disabled={getDisabled(values)}
                    id='next1'
                    type={step === 5 ? 'submit' : 'button'}
                    className='next-btn bg-secondary text-white font-bold text-xl w-[10rem] cursor-pointer uppercase py-2 px-5 rounded-full mt-2 font-Monteserrat border-2 hover:text-white border-secondary hover:bg-secondary/80 hover:border-opacity-0   disabled:bg-secondary/80 disabled:border-opacity-0 disabled:cursor-not-allowed'
                  >
                    {getButtonLabel()}
                  </button>
                )}
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};
